<template>
  <div class="register-card-credit">
    <card-header ref="header" :node="headerTitle" />
    <div id="content-scroll" class="content-scroll">
      <!-- <input type="number" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" /> -->
      <ValidationObserver v-slot="{ handleSubmit }">
        <form class="content" @submit.prevent="handleSubmit(onSubmit)">
          <ValidationProvider
            name="クレジットカード番号"
            rules="required|ccn"
            v-slot="{ errors }"
          >
            <div class="content__credit text-left">
              <label class="content__credit__label f-w6"
                >クレジットカード番号 <span class="required">*</span>
              </label>
              <div
                class="content__credit__img d-flex justify-content-between align-items-center"
              >
                <img src="@/assets/image/credit_card/Visa_Logo.svg" alt="" />
                <img src="@/assets/image/credit_card/master_card.svg" alt="" />
                <img src="@/assets/image/credit_card/unnamed.svg" alt="" />
                <img src="@/assets/image/credit_card/jcb_82032.svg" alt="" />
                <img
                  src="@/assets/image/credit_card/american-express.svg"
                  alt=""
                />
              </div>
              <input
                v-model="creditCardNumber"
                class="input-element content__credit__input f-w3"
              />
              <span class="error f-w3 text-left" id="error-credit">{{
                errors[0]
              }}</span>
            </div>
          </ValidationProvider>
          <div class="content__credit text-left">
            <label class="content__credit__label f-w6"
              >カード有効期限 <span class="required">*</span></label
            >
            <div class="d-flex">
              <div
                class="content__credit__month d-flex justify-content-between align-items-center"
              >
                <OfferSearchSelect
                  :options="monthOptions"
                  v-model="month"
                  @select-option="month = $event"
                />
                <span>月</span>
              </div>
              <div
                class="content__credit__year d-flex justify-content-between align-items-center"
              >
                <OfferSearchSelect
                  :options="yearOptions"
                  v-model="year"
                  @select-option="year = $event"
                />
                <span>年</span>
              </div>
            </div>
          </div>
          <div class="error text-left">{{ dateError }}</div>
          <ValidationProvider
            name="クレジットカード名義"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="content__credit text-left">
              <label class="content__credit__label f-w6"
                >クレジットカード名義 <span class="required">*</span>
              </label>
              <input
                type="text"
                v-model="creditCardName"
                class="content__credit__input f-w3"
              />
              <span class="error f-w3 text-left">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
          <ValidationProvider
            name="セキュリティコード"
            rules="required|cvc"
            v-slot="{ errors }"
          >
            <div class="content__credit text-left">
              <label for="" class="content__credit__label f-w6"
                >セキュリティコード <span class="required">*</span></label
              >
              <input
                type="text"
                v-model="codeSecurity"
                class="content__credit__input f-w3"
              />
              <span class="error f-w3 text-left">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
          <div class="content__action d-flex f-w3 justify-content-center">
            <div
              class="content__action__cencel d-flex justify-content-center align-items-center"
              @click="nextPage"
            >
              キャンセル
            </div>
            <button
              type="submit"
              class="content__action__register"
              @click="checkError()"
            >
              登録する
            </button>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <card-footer ref="footer" />
  </div>
</template>

<script>
import CardHeader from "@/components/CardHeader";
import CardFooter from "@/components/CardFooter.vue";
import OfferSearchSelect from "../../views/Cast/OfferSearchSelect";
import valid from "card-validator";
import { mapActions, mapGetters } from "vuex";
import Cookies from "js-cookie";
import firebase from "firebase";
export default {
  name: "MyPage",
  components: {
    "card-header": CardHeader,
    "card-footer": CardFooter,
    OfferSearchSelect
    // Cleave,
  },
  metaInfo() {
    return {
      title: "クレジットカード設定",
      titleTemplate: "%s | PreGo"
    };
  },
  props: {
    profileId: [String, Number],
    castId: [String, Number],
    isDeleteUser: [Boolean]
  },
  data() {
    return {
      headerTitle: {
        text: "クレジットカード設定",
        isShowTitle: true,
        isShowLogo: false,
        back: null
      },
      isShowerrorsRequired: false,
      ccNumber: null,
      creditCardNumber: null,
      creditCardName: null,
      codeSecurity: null,
      monthOptions: [
        { title: "1" },
        { title: "2" },
        { title: "3" },
        { title: "4" },
        { title: "5" },
        { title: "6" },
        { title: "7" },
        { title: "8" },
        { title: "9" },
        { title: "10" },
        { title: "11" },
        { title: "12" }
      ],
      yearOptions: [],
      month: null,
      year: null,
      cardDate: null,
      dateError: null,
      isFromOrder: false,
      isUpdateCard: false,
      backMessageDetail: false,
      routeNameTo: "InfoCardCredit"
    };
  },
  watch: {
    month() {
      this.dateErrorFunction();
    },
    year() {
      this.dateErrorFunction();
    },
    creditCardNumber() {
      var creditcardutils = require("creditcardutils");
      let creditCardNumberFormat = creditcardutils.formatCardNumber(
        this.creditCardNumber
      );
      this.creditCardNumber = creditCardNumberFormat;
    }
  },
  computed: {
    ...mapGetters({
      cardInfo: "payment-stripe/cardInfo",
      authUser: "auth/user"
    })
  },
  async created() {
    var d = new Date();
    var n = d.getFullYear();
    var year = parseInt(n.toString().substr(2));
    for (let i = year; i < year + 50; i++) {
      this.yearOptions.push({ title: i });
    }
    this.$root.$refs.loading.start();
    await this.$store.dispatch("payment-stripe/getCreditCard");
    if (this.isUpdateCard || this.isDeleteUser) {
      this.$root.$refs.loading.finish();
      return;
    }
    if (this.cardInfo) {
      this.$router.push({ name: "InfoCardCredit" });
      this.$root.$refs.loading.finish();
      return;
    }
    this.$root.$refs.loading.finish();
  },
  methods: {
    ...mapActions("payment-stripe", [
      "addPaymentMonthPacket",
      "getPaymentMonthPacket"
    ]),
    ...mapActions("auth", ["getUsersPackage"]),
    nextPage() {
      this.$root.$refs.loading.start();
      if (this.isFromOrder) {
        this.$router.push({
          name: "MenCreateOrderStepOne",
          params: { profileId: this.profileId }
        });
        this.$root.$refs.loading.finish();
        return;
      }
      this.$router.push({ name: "MenMyPage" });
      this.$root.$refs.loading.finish();
    },
    checkError() {
      this.dateErrorFunction();
      this.isShowerrorsRequired = true;
    },
    async onSubmit() {
      this.$root.$refs.loading.start();
      await this.$store
        .dispatch("payment-stripe/addCreditCard", {
          name: this.creditCardName,
          number: this.creditCardNumber,
          exp_month: this.monthOptions[this.month].title,
          exp_year: this.yearOptions[this.year].title,
          cvc: this.codeSecurity
        })
        .then(async () => {
          if (this.isDeleteUser) {
            await this.$store
              .dispatch("payment-stripe/cancelPaymentPacketDeleteUser")
              .then(async () => {
                await this.$store
                  .dispatch("userApp/deleteUser")
                  .then(response => {
                    this.$root.$refs.loading.finish();
                    if (response?.message && !response.success) {
                      this.$refs.modalAcceptDeleteUser.closeModal();
                      this.$toast(response?.message, "通知", "danger");
                      return;
                    }
                    Cookies.remove("prego_token");
                    firebase.auth().signOut();
                    this.$router.push({
                      name: "TopRegister",
                      params: { delete: "success" }
                    });
                  })
                  .catch(() => {
                    this.$toast(
                      "ユーザーの削除に失敗しました",
                      "不合格",
                      "danger"
                    );
                    this.$root.$refs.loading.finish();
                  });
              })
              .catch(() => {
                this.$root.$refs.loading.finish();
              });
            return;
          }
          this.$root.$refs.loading.finish();

          // if has castId return create direct order
          if (this.castId) {
            this.$router.push({
              path: "/create-order/for/" + this.castId
            });
            return;
          }

          // if have profileId return create order
          if (this.isFromOrder) {
            this.$router.push({
              name: "MenCreateOrderStepOne",
              params: { profileId: this.profileId }
            });
            return;
          }

          // return info card
          if (this.$route.query.identify) {
            this.$router.push({
              name: "InfoCardCredit",
              query: { identify: true }
            });
          } else {
            if (this.backMessageDetail) {
              this.$router.go(-1);
            } else {
              this.$router.push({ name: this.routeNameTo });
            }
          }
          let message = "クレジットカードの登録が完了しました。";
          if (localStorage.getItem("package_user_" + this.authUser.user_id)) {
            if (this.backMessageDetail) {
              message = "登録が完了しました。お相手にメッセージが送れます";
            } else {
              message = "登録が完了しました。";
            }
            this.addPaymentMonthPacket({
              packet_type: localStorage.getItem(
                "package_user_" + this.authUser.user_id
              )
            })
              .then(() => {
                if (this.backMessageDetail) {
                  this.$emit("close-modal");
                  this.$emit("add-success");
                  this.$parent.$parent.$data.showContinueDatePopup = true;
                  this.$parent.$parent.$data.readonlyMsg = 0;
                }
                localStorage.removeItem(
                  "package_user_" + this.authUser.user_id
                );
                setTimeout(() => {
                  this.$toast(message, "通知", "success");
                  this.getPaymentMonthPacket();
                  this.getUsersPackage();
                }, 1000);
                return;
              })
              .catch(() => {
                this.$toast("登録に失敗しました。", "通知", "danger");
              });
            localStorage.removeItem("package_user_" + this.authUser.user_id);
          }
        })
        .catch(() => {
          this.$root.$refs.loading.finish();
        });
    },
    getYear() {},
    formatDate() {
      if (this.month != null && this.year == null) {
        return this.monthOptions[this.month].title;
      } else if (this.month == null && this.year != null) {
        return this.yearOptions[this.year].title;
      } else if (this.month != null && this.year != null) {
        return (
          this.monthOptions[this.month].title +
          "/" +
          this.yearOptions[this.year].title
        );
      }
      return null;
    },
    dateErrorFunction() {
      this.cardDate = this.formatDate();
      var validDate = valid.expirationDate(this.cardDate).isValid;
      if (!validDate) {
        this.dateError = "カード有効期限が不正です。";
        return false;
      }
      this.dateError = "";
      return true;
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name === "MenCreateConfirmOrder") {
        vm.isFromOrder = true;
      }
      if (from.name === "InfoCardCredit") {
        vm.isUpdateCard = true;
      }
      if (from.name === "MessageDetail") {
        vm.backMessageDetail = true;
      }
      if (from.name === "DistanceView") {
        vm.backMessageDetail = true;
      }
      if (from.name === "Home" || from.name === "MenMyPage") {
        vm.routeNameTo = "MenOrderList";
      }
    });
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/men/registerCardCredit.scss";
@import "~@/assets/scss/_fontFamily.scss";
.content-scroll {
  // overflow-y: scroll;
  padding-top: 40px;
  padding-bottom: 40px;
  overflow-x: hidden;
  // height: calc(100vh - 108px);
}

@media screen and (min-width: 1200px) {
  // .content-scroll {
  //   // height: calc(100vh - 152px);
  // }
  .register-card-credit {
    & /deep/ .offer-select {
      height: 55px;
    }
  }
}
</style>
