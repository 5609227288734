<template>
  <div
    class="offer-select"
    @blur="show = false"
    :tabindex="tabindex"
    :class="show ? 'active' : ''"
  >
    <div class="offer-select__value" @click="show = !show">
      {{ titleOfSelected }}
    </div>
    <div class="offer-select__options">
      <div
        class="offer-select__item"
        :class="key === value ? 'active' : ''"
        v-for="(item, key) of options"
        :key="key"
        @click="chooseOption(key)"
      >
        {{ item.title }}
      </div>
    </div>
    <div class="drop-down" @click="show = !show"></div>
  </div>
</template>

<script>
export default {
  name: "OfferSearchSelect",
  props: {
    options: {
      type: Array,
      require: true
    },
    value: {
      require: false
    },
    tabindex: {
      type: Number,
      require: null,
      default: 0
    }
  },
  computed: {
    titleOfSelected: function() {
      let title = "";
      if (this.value != null) {
        title = this.options[this.value].title;
      } else {
        title = "";
      }

      return title;
    }
  },
  data() {
    return {
      show: false
    };
  },
  methods: {
    chooseOption(key) {
      this.show = false;
      this.$emit("select-option", key);
    }
  }
};
</script>

<style lang="scss" scoped>
.offer-select {
  height: 40px;
  width: 100%;
  border-radius: 5px;
  position: relative;
  border: 1px solid #e6e6e6;
  background-color: #ffffff;
  user-select: none;
  cursor: pointer;
  &:focus {
    box-shadow: none;
    outline: none;
  }
  &:active {
    box-shadow: none;
    outline: none;
  }
  .drop-down {
    height: 100%;
    width: 40px;
    position: absolute;
    top: 0;
    right: 0;
    &::after {
      content: "";
      position: absolute;
      right: 17px;
      top: calc(50% - 3px);
      border-top: 6px solid #9c9c9c;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      transition: ease 0.5s;
    }
  }
  &.active {
    .offer-select__options {
      opacity: 1;
      transform: scaleY(1);
    }
    .drop-down {
      &::after {
        transform: rotate(180deg);
      }
    }
  }
  &__value {
    height: 100%;
    width: 100%;
    font-family: "Hiragino Kaku Gothic Pro W3";
    text-align: left;
    display: flex;
    align-items: center;
    padding: 0 18px;
    margin-bottom: 3px;
    font-size: 14px;
  }

  &__options {
    position: relative;
    z-index: 2;
    opacity: 0;
    width: 100%;
    transition: ease 0.3s;
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
    transform-origin: top;
    transform: scaleY(0);
    box-shadow: 0 0 15px #00000029;
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__item {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 18px;
    font-size: 14px;
    font-family: "Hiragino Kaku Gothic Pro W3";
    &.active,
    &:hover {
      background-color: #e5e5e5;
      color: #000;
    }
  }
}
</style>
